import React from "react";
import { Redirect, Route, useHistory,Switch,useLocation } from "react-router-dom";
import AdminLayout from "./layout/Admin/AdminLayout";
import AccountFee from "./screens/AccountFee";
import AccountSettings from "./screens/AccountSettings";
import Dashboard from "./screens/Admin/Dashboard";
import CategoryListScreen from "./screens/CategoryListScreen";
// import ContachInfo from "./screens/ContacInfo";
import MyEarnings from "./screens/MyEarnings";
import Notifications from "./screens/Notifications";
import OrderListScreen from "./screens/OrderListScreen";
import OrderReceived from "./screens/OrderReceived";
import PaymentMethods from "./screens/PaymentMethods";
import ProductListScreen from "./screens/ProductListScreen";
import ProfileScreen from "./screens/ProfileScreen";
import PromoCode from "./screens/PromoCode";
import Specifications from "./screens/Specifications";
import Support from "./screens/Support";
import UserListScreen from "./screens/UserListScreen";
import VariantScreen from "./screens/VariantScreen";
import Wishlist from "./screens/Wishlist";
import VendorProfile from "./screens/VendorProfile";
import OrderDetailsScreen from "./screens/OrderDetailsScreen";
import CancelledOrdersScreen from "./screens/CancelledOrdersScreen";
import OpenOrderScreen from "./screens/OpenOrderScreen";
import DeliveredOrdersScreen from "./screens/DeliveredOrdersScreen";
import VendorAllCancelledOrders from "./screens/VendorAllCancelledOrders";
import VendorDeliveredOrders from "./screens/VendorDeliveredOrders";
import AllOrdersAdmin from "./screens/AllOrdersAdmin";
import AddProduct from "./screens/AddProduct";
import ProductEditScreen from "./screens/ProductEditScreen";
import UserEditScreen from "./screens/UserEditScreen";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import SocialScreen from "./screens/Admin/SocialScreen";
import ExternalAdminLogin from "./screens/Admin/ExternalAdminLogin";
import ReportedPosts from "./screens/Admin/ReportedPosts";
import UserManagement from "./screens/Admin/Event/UserManagement";
import OrderManagement from "./screens/Admin/Event/OrderManagement";
import OrderAttendeeList from "./screens/Admin/Event/OrderAttendeeList";
import EventManagement from "./screens/Admin/Event/EventManagement";
import WalletManagement from './screens/Admin/WalletManagement'
import second from '.'
import NotFound from "./components/404Page";
import { adminLogout } from "./actions/userActions";

const AdminRoutes = () => {
  const { userInfo } = useSelector((state) => state.userLogin);

  const history = useHistory();
  const location=useLocation();
  const dispatch = useDispatch();


  const isAdminLogout = new URLSearchParams(location.search).get('isAdminLogout') === 'true';
  useEffect(() => {
    if (isAdminLogout) {
      dispatch(adminLogout());
    }
  }, []);

  return (
    <>
      {userInfo && userInfo?.isAdminLogin === true ? (
        <AdminLayout>
          <Switch>
          <Route exact path="/admin" component={Dashboard} />
          <Route
            path="/paymentMethods/:fromReturn?"
            component={PaymentMethods}
            exact
          />
          <Route path="/platform-fee" component={AccountFee} />

          <Route path="/admin/my-earnings" component={MyEarnings} />
          <Route
            path="/admin/ordersReceived/:pageNo?"
            component={OrderReceived}
            exact
          />
          <Route
            path="/auth/admin/login/:authId?/:redirect?"
            component={ExternalAdminLogin}
            exact
          />

          <Route
            path="/admin/productlist"
            component={ProductListScreen}
            exact
          />
          <Route
            path="/admin/specifications"
            component={Specifications}
            exact
          />
          <Route
            path="/admin/categorylist"
            component={CategoryListScreen}
            exact
          />
          <Route path="/admin/userlist" component={UserListScreen} exact />
          <Route path="/admin/promocode" component={PromoCode} exact />
          <Route path="/admin/variants" component={VariantScreen} exact />
          <Route path="/admin/support" component={Support} exact />
          <Route
            path="/admin/raised-tickets/:type?"
            component={Support}
            exact
          />
          <Route
            path="/admin/accountSetting"
            component={AccountSettings}
            exact
          />

          <Route
            path="/order-details/:orderId"
            component={OrderDetailsScreen}
            exact
          />
          <Route
            path="/open-orders/:pageNo?"
            component={OpenOrderScreen}
            exact
          />
          <Route
            path="/cancelled-orders/:pageNo?"
            component={CancelledOrdersScreen}
            exact
          />
          <Route
            path="/delivered-orders/:pageNo?"
            component={DeliveredOrdersScreen}
            exact
          />
          <Route
            path="/admin/vendorAllCancelledOrders/:pageNo?"
            component={VendorAllCancelledOrders}
            exact
          />
          <Route
            path="/admin/vendorAllDeliveredOrders/:pageNo?"
            component={VendorDeliveredOrders}
            exact
          />

          <Route
            path="/admin/all-orders/:pageNo?"
            component={AllOrdersAdmin}
            exact
          />
          <Route
            path="/admin/approve-return-orders/:pageNo?"
            component={VendorAllCancelledOrders}
            exact
          />
          <Route path="/admin/addProduct" component={AddProduct} exact />
          <Route path="/admin/editProduct" component={AddProduct} exact />
          <Route path="/admin/user/:id/edit" component={UserEditScreen} exact />

          <Route
            path="/admin/productlist/:pageNumber"
            component={ProductListScreen}
            exact
          />
          <Route
            path="/admin/product/:id/edit"
            component={ProductEditScreen}
            exact
          />
          <Route path="/admin/orderlist" component={OrderListScreen} exact />
          <Route
            path="/admin/social/posts/user"
            component={SocialScreen}
            exact
          />
          <Route
            path="/admin/social/reported/posts"
            component={ReportedPosts}
            exact
          />
          <Route path="/admin/event/users" component={UserManagement} exact />
          <Route path="/admin/event/orders" component={OrderManagement} exact />
          <Route
            path="/admin/event/orders/attendees/:orderId"
            component={OrderAttendeeList}
            exact
          />
          <Route path="/admin/event/events" component={EventManagement} exact />
          <Route path="/admin/wallet" component={WalletManagement} exact />
          <Route path="*" component={NotFound} />
          </Switch>

          {/* <Redirect to="/admin" /> */}
        </AdminLayout>
        
      ) : (
        <Redirect to="/admin/login" />
      )}

      {/* <Redirect from="/admin" to="/admin/dashboard" /> */}
    </>
  );
};

export default AdminRoutes;
