import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import GoogleLogin from "react-google-login";
import { useDispatch } from "react-redux";
import Axios from "axios";
import { syncCartToDB } from "../actions/cartActions";
import { gapi } from "gapi-script";
import { USER_LOGIN_SUCCESS } from "../constants/userConstants";
import base64 from "base-64";
const LoginLeftbar = (props) => {
  const dispatch = useDispatch();

  const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;

  useEffect(() => {
    gapi.load("client:auth2", () => {
      gapi.auth2.init({
        clientId: GOOGLE_CLIENT_ID,
      });
    });
  }, []);

  const redirect = props.redirect;
  const referralId = props.referralId;
  const responseGoogle = async (response) => {
    try {
      const searchParams = new URLSearchParams(window.location.search);

      const platform = searchParams.get("platform");

      let res = await Axios.post(`/api/google/signup`, {
        tokenId: response.tokenId,
      });

      dispatch({
        type: "USER_REGISTER_SUCCESS",
        payload: res.data,
      });

      dispatch({
        type: "USER_LOGIN_SUCCESS",
        payload: res.data,
      });

      localStorage.setItem("userInfo", JSON.stringify(res.data));
      dispatch(syncCartToDB());

      if (redirect && redirect !== "/") {
        let encKey = base64.encode(base64.encode(res.data.masterToken));
        if (platform === "classes") {
          window.location.href = `${process.env.REACT_APP_CLASSES_URL}/auth/${encKey}?isPromoter=true&redirect=${redirect}`;
          return;
        }
        if (redirect?.includes("event")) {
          if (redirect.includes("?")) {
            window.location.href = `${redirect}&Token=${encKey}`;
          } else {
            window.location.href = `${redirect}?Token=${encKey}`;
          }
        } else {
          window.location.href = redirect;
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const responseErrorGoogle = (param) => {
    console.log(param);
  };

  const responseFacebook = async (response) => {
    try {
      let res = await Axios.post(`/api/facebook/signup`, {
        accessToken: response.accessToken,
        userId: response.userID,
        data: response,
      });
      dispatch({
        type: "USER_REGISTER_SUCCESS",
        payload: res.data,
      });

      dispatch({
        type: "USER_LOGIN_SUCCESS",
        payload: res.data,
      });

      localStorage.setItem("userInfo", JSON.stringify(res.data));
      dispatch(syncCartToDB());
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="login-leftbar">
      <div className="login-leftImg">
        <img src="/img/home-img-4.jpg" alt="img" />
      </div>
      <div className="login-leftContent">
        <div className="leftContent-wraper">
          <GoogleLogin
            clientId={GOOGLE_CLIENT_ID}
            render={(renderProps) => (
              <div className="google-btn">
                <a href="#" onClick={renderProps.onClick} className="btn">
                  <img src="/img/google-icon.png" alt="img" /> Login with Google
                </a>
              </div>
            )}
            buttonText="Login"
            onSuccess={responseGoogle}
            onFailure={responseErrorGoogle}
            cookiePolicy={"single_host_origin"}
          />

          <FacebookLogin
            appId={process.env.REACT_APP_FACEBOOK_APP_ID}
            // autoLoad
            // onClick={componentClicked}
            callback={responseFacebook}
            render={(renderProps) => (
              <div className="facebook-btn">
                <a href="#" onClick={renderProps.onClick} className="btn">
                  <i className="fab fa-facebook-f"></i> Login with Facebook
                </a>
              </div>
            )}
          />
          {props.type != "signUp" && (
            <div className="signup-btn">
              <p>Don’t have an account? </p>
              <Link
                className="btn"
                to={
                  redirect
                    ? `/register?${
                        referralId ? `referralId=${referralId}` : ""
                      }&redirect=${redirect}`
                    : "/register"
                }
              >
                Sign up
              </Link>
            </div>
          )}
          {props.type != "login" && (
            <div className="signup-btn">
              <p>Already have Account? </p>
              <Link
                className="btn"
                to={
                  redirect
                    ? `/Login?${
                        referralId ? `referralId=${referralId}` : ""
                      }&redirect=${redirect}`
                    : "/Login"
                }
              >
                Login
              </Link>
            </div>
          )}
          {/* {props.type !='sellersignUp' && <div className="signup-btn" style={{ margin: "13px 0px 0px 0px" }}>
            <Link 
              className="btn"
              to={
                redirect
                  ? `/register?u=Seller&redirect=${redirect}`
                  : "/register?u=seller"
              }
            >
              Want to Register as Seller?
            </Link>
          </div>} */}
        </div>
      </div>
    </div>
  );
};
export default LoginLeftbar;
