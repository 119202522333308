import axios from "axios";
import {
  USER_DETAILS_FAIL,
  USER_DETAILS_REQUEST,
  USER_DETAILS_SUCCESS,
  USER_LOGIN_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT,
  USER_REGISTER_FAIL,
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
  USER_UPDATE_PROFILE_FAIL,
  USER_UPDATE_PROFILE_REQUEST,
  USER_UPDATE_PROFILE_SUCCESS,
  USER_DETAILS_RESET,
  USER_LIST_FAIL,
  USER_LIST_SUCCESS,
  USER_LIST_REQUEST,
  USER_LIST_RESET,
  USER_DELETE_REQUEST,
  USER_DELETE_SUCCESS,
  USER_DELETE_FAIL,
  USER_UPDATE_FAIL,
  USER_UPDATE_SUCCESS,
  USER_UPDATE_REQUEST,
} from "../constants/userConstants";
import { ORDER_LIST_MY_RESET } from "../constants/orderConstants";
import { syncCartToDB } from "./cartActions";
import { EVENT_URL } from "../urls/OtherUrls";
import base64 from "base-64";
import { toast } from "react-toastify";
export const adminLogin = (email, password, history) => async (dispatch) => {
  try {
    dispatch({
      type: USER_LOGIN_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.post(
      "/api/users/admin/login",
      { email, password },
      config
    );
    localStorage.setItem("userInfo", JSON.stringify(data));
    dispatch(syncCartToDB());

    dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: data,
    });
    history.push("/admin");
  } catch (error) {
    dispatch({
      type: USER_LOGIN_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const adminLogout = () => async (dispatch) => {
  let user = JSON.parse(localStorage.getItem("userInfo"));
  let config = {
    headers: {
      Authorization: `Bearer ${user.token}`,
    },
  };

  try {
    await axios.put(`/api/users/admin/logout`, {}, config);
  } catch (err) {
    console.log(err);
  }

  localStorage.removeItem("userInfo");
  localStorage.removeItem("cartItems");
  localStorage.removeItem("shippingAddress");
  localStorage.removeItem("paymentMethod");
  dispatch({ type: USER_LOGOUT });
  dispatch({ type: USER_DETAILS_RESET });
  dispatch({ type: ORDER_LIST_MY_RESET });
  dispatch({ type: USER_LIST_RESET });
  document.location.href = "/login";
};

export const login = (email, password, redirect) => async (dispatch) => {
  try {
    dispatch({
      type: USER_LOGIN_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.post(
      "/api/users/login",
      { email, password },
      config
    );
    const searchParams = new URLSearchParams(window.location.search);
    const referralId = searchParams.get("referralId");
    localStorage.setItem("userInfo", JSON.stringify(data));
    dispatch(syncCartToDB());

    dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: data,
    });

    if (redirect && redirect !== "/") {
      let encKey = base64.encode(base64.encode(data.masterToken));
      if (redirect.includes("?")) {
        window.location.href = `${redirect}&Token=${encKey}`;
      } else {
        if (redirect.startsWith(process.env.REACT_APP_CLASSES_URL)) {
          window.location.href = `${process.env.REACT_APP_CLASSES_URL}/auth/${encKey}?isPromoter=true&redirect=${redirect}`;
        } else {
          window.location.href = `${redirect}?Token=${encKey}${
            referralId ? `&referralId=${referralId}` : ""
          }`;
        }
      }
    }
  } catch (error) {
    dispatch({
      type: USER_LOGIN_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const externalLogin = (userInfo) => async (dispatch) => {
  try {
    localStorage.setItem("userInfo", JSON.stringify(userInfo));
    dispatch(syncCartToDB());

    dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: userInfo,
    });
  } catch (error) {
    dispatch({
      type: USER_LOGIN_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const externalAdminLogin = (userInfo) => async (dispatch) => {
  try {
    localStorage.setItem("userInfo", JSON.stringify(userInfo));
    dispatch(syncCartToDB());

    dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: userInfo,
    });
  } catch (error) {
    dispatch({
      type: USER_LOGIN_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const logout = () => async (dispatch) => {
  let user = JSON.parse(localStorage.getItem("userInfo"));
  let config = {
    headers: {
      Authorization: `Bearer ${user.token}`,
    },
  };

  try {
    await axios.put(`/api/users/logout`, {}, config);
  } catch (err) {
    console.log(err);
  }

  localStorage.removeItem("userInfo");
  localStorage.removeItem("cartItems");
  localStorage.removeItem("shippingAddress");
  localStorage.removeItem("paymentMethod");
  dispatch({ type: USER_LOGOUT });
  dispatch({ type: USER_DETAILS_RESET });
  dispatch({ type: ORDER_LIST_MY_RESET });
  dispatch({ type: USER_LIST_RESET });
  document.location.href = "/login";
};

export const register =
  (
    name,
    lastName,
    dob,
    email,
    password,
    companyName,
    companyRegNo,
    companyEmail,
    companyAddress,
    type,
    gender,
    countryCode,
    userName,
    redirect
  ) =>
  async (dispatch) => {
    const searchParams = new URLSearchParams(window.location.search);
    const platform = searchParams.get("platform");
    const referralId = searchParams.get("referralId");
    try {
      dispatch({
        type: USER_REGISTER_REQUEST,
      });

      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      if (type == "sellersignUp") {
        type = "vendor";
      } else {
        type = "customer";
      }
      const { data } = await axios.post(
        "/api/users",
        {
          name,
          email,
          password,
          lastName,
          dob,
          companyName,
          companyRegNo,
          companyEmail,
          companyAddress,
          userType: type,
          gender,
          countryCode,
          userName,
        },
        config
      );

      dispatch({
        type: USER_REGISTER_SUCCESS,
        payload: data,
      });
      localStorage.setItem("userInfo", JSON.stringify(data));
      dispatch(syncCartToDB());
      dispatch({
        type: USER_LOGIN_SUCCESS,
        payload: data,
      });

      if (redirect && redirect !== "/") {
        let encKey = base64.encode(base64.encode(data.masterToken));
        if (platform === "classes") {
          window.location.href = `${process.env.REACT_APP_CLASSES_URL}/auth/${encKey}?isPromoter=true&redirect=${redirect}`;
          return;
        } else if (redirect?.includes("event")) {
          if (redirect.includes("?")) {
            window.location.href = `${redirect}&Token=${encKey}${
              referralId ? `&referralId=${referralId}` : ""
            }`;
          } else {
            window.location.href = `${redirect}?Token=${encKey}${
              referralId ? `&referralId=${referralId}` : ""
            }`;
          }
        } else {
          window.location.href = redirect;
        }
      }
    } catch (error) {
      dispatch({
        type: USER_REGISTER_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const getUserDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_DETAILS_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`/api/users/${id}`, config);

    dispatch({
      type: USER_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Not authorized, token failed") {
      dispatch(logout());
    }
    dispatch({
      type: USER_DETAILS_FAIL,
      payload: message,
    });
  }
};

export const updateUserProfile = (user) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_UPDATE_PROFILE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    console.log(user);
    const { data } = await axios.put(`/api/users/profile`, user, config);
    toast.success("User Detail Updated successfully");

    dispatch({
      type: USER_UPDATE_PROFILE_SUCCESS,
      payload: data,
    });
    dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: data,
    });
    localStorage.setItem("userInfo", JSON.stringify(data));
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Not authorized, token failed") {
      dispatch(logout());
    }
    dispatch({
      type: USER_UPDATE_PROFILE_FAIL,
      payload: message,
    });
  }
};

export const listUsers =
  ({ filter, searchText }) =>
  async (dispatch, getState) => {
    // console.log(filter);
    try {
      dispatch({
        type: USER_LIST_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.get(
        `/api/users?${searchText !== "" ? `search=${searchText}` : ""}&${
          filter.isArtist === true ? `isArtist=${Boolean(filter.isArtist)}` : ""
        }&${filter.userType !== "" ? `userType=${filter.userType}` : ""}&${
          filter.isVerified === true
            ? `isVerified=${Boolean(filter.isVerified)}`
            : ""
        }`,
        config
      );

      dispatch({
        type: USER_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      if (message === "Not authorized, token failed") {
        dispatch(logout());
      }
      dispatch({
        type: USER_LIST_FAIL,
        payload: message,
      });
    }
  };

export const deleteUser = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_DELETE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    await axios.delete(`/api/users/${id}`, config);

    dispatch({ type: USER_DELETE_SUCCESS });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Not authorized, token failed") {
      dispatch(logout());
    }
    dispatch({
      type: USER_DELETE_FAIL,
      payload: message,
    });
  }
};

export const updateUser = (user) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_UPDATE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.put(`/api/users/${user._id}`, user, config);

    dispatch({ type: USER_UPDATE_SUCCESS });

    dispatch({ type: USER_DETAILS_SUCCESS, payload: data });

    dispatch({ type: USER_DETAILS_RESET });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Not authorized, token failed") {
      dispatch(logout());
    }
    dispatch({
      type: USER_UPDATE_FAIL,
      payload: message,
    });
  }
};

export const updateVendor = () => async (dispatch, getState) => {
  let userInfo = getState().userLogin.userInfo;
  userInfo.userType = "vendor";
  dispatch({
    type: "REGISTER_VENDOR",
    payload: userInfo,
  });
  localStorage.setItem("userInfo", JSON.stringify(userInfo));
};

export const updateProfilePic = (url) => async (dispatch, getState) => {
  let userInfo = getState().userLogin.userInfo;
  userInfo.profilePic = url;
  dispatch({
    type: "UPDATE_PROFILE_PIC",
    payload: userInfo,
  });
  localStorage.setItem("userInfo", JSON.stringify(userInfo));
};

export const updateCoverPic = (url) => async (dispatch, getState) => {
  let userInfo = getState().userLogin.userInfo;
  userInfo.coverPic = url;
  dispatch({
    type: "UPDATE_COVER_PIC",
    payload: userInfo,
  });
  localStorage.setItem("userInfo", JSON.stringify(userInfo));
};

export const readNotification = () => async (dispatch, getState) => {
  let count = getState().notification.unNotification;
  // console.log({ count })

  if (count > 0) {
    console.log(true);
    --count;
  }
  dispatch({
    type: "UPDATE_READ_NOTI",
    payload: count,
  });
  localStorage.setItem("unreadNotifications", count);
};

export const readAllNotifications = () => async (dispatch, getState) => {
  let count = getState().notification.unNotification;
  // console.log({ count })

  count = 0;

  dispatch({
    type: "UPDATE_READ_NOTI",
    payload: count,
  });
  localStorage.setItem("unreadNotifications", count);
};

export const setNotification = (data) => async (dispatch, getState) => {
  dispatch({
    type: "SET_NOTI",
    payload: data,
  });
  localStorage.setItem("unreadNotifications", data);
};
